export const urls = {
  index: '/',
  walletCreate: '/create',
  walletImport: '/import',
  account: '/accounts/:index',
};

export const getPageName = (url: string): string => {
  const options = {
    [urls.walletCreate]: 'Create Wallet',
    [urls.walletImport]: 'WalletImport',
    [urls.account]: 'Account',
  };
  return options[url] ?? '';
};
