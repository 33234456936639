import React, { useCallback, useMemo } from 'react';
import { Button, Select, SelectOption, Stack, Typography } from '@lfg/core';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { WalletImportMnemonic } from 'components/WalletImportMnemonic';
import { WalletImportSeed } from 'components/WalletImportSeed';

import { resolver } from './resolver';
import styles from './WalletImportRecovery.css';

export type WalletImportRecoveryFormData =
  | {
      option: 'mnemonic';
      words?: { value: string }[];
    }
  | {
      option: 'seed';
      seed?: string;
    };

interface WalletImportRecoveryProps {
  onComplete: (payload: WalletImportRecoveryFormData) => void;
}

export const WalletImportRecovery: React.FC<WalletImportRecoveryProps> = ({ onComplete }) => {
  const { t } = useTranslation();

  const methods = useForm<WalletImportRecoveryFormData>({
    defaultValues: {
      words: Array(24).fill({ value: '' }),
    },
    resolver,
  });

  const selectedOption = methods.watch('option');

  const options: SelectOption[] = useMemo(
    () => [
      { value: 'mnemonic', label: t('Secret key phrase') },
      { value: 'seed', label: t('64 character recovery seed') },
    ],
    [t]
  );

  const handleSubmit = useCallback(
    (data: WalletImportRecoveryFormData) => {
      onComplete(data);
    },
    [onComplete]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className={styles.form}>
        <Stack spacing={2} alignItems="flex-start">
          <Typography variant="headingLg">{t('Import your wallet')}</Typography>
          <Typography variant="body">{t('Choose a way to import your wallet')}</Typography>

          <Select
            {...methods.register('option')}
            error={methods.formState.errors.option?.message}
            fullWidth
            label={t('Choose an option')}
            options={options}
          />

          {
            {
              seed: <WalletImportSeed />,
              mnemonic: <WalletImportMnemonic />,
            }[selectedOption]
          }

          <Stack width="100%">
            <Button type="submit">{t('Continue')}</Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
