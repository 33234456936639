import { SvgIcon } from '@mui/material';
import {
  AddIcon,
  CameraIcon,
  CloseIcon,
  CopyIcon,
  ImportIcon,
  QrCodeIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from '@lfg/icons';

export type IconTypes = 'add' | 'camera' | 'close' | 'copy' | 'import' | 'qr-code' | 'visibility' | 'visibility-off';

export const MappedIcons: Record<IconTypes, typeof SvgIcon> = {
  add: AddIcon,
  camera: CameraIcon,
  close: CloseIcon,
  copy: CopyIcon,
  import: ImportIcon,
  'qr-code': QrCodeIcon,
  visibility: VisibilityIcon,
  'visibility-off': VisibilityOffIcon,
};
