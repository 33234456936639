import { Container } from 'inversify';

import { Bip39Service } from 'services/Bip39Service';
import { DatabaseService } from 'services/DatabaseService';
import { WalletService } from 'services/WalletService';
import { IBip39Service, IDatabaseService, IWalletService } from 'services/types';

import { TYPES } from './types';

const container = new Container();

container.bind<IBip39Service>(TYPES.Bip39Service).to(Bip39Service);
container.bind<IDatabaseService>(TYPES.DatabaseService).to(DatabaseService);
container.bind<IWalletService>(TYPES.WalletService).to(WalletService);

export default container;
