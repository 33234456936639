import React, { useState } from 'react';
import { IconButton, Modal, QrReader } from '@lfg/core';
import { useTranslation } from 'react-i18next';

interface ScanQRCodeProps {
  onScan: (payload: string) => void;
}

export const ScanQRCode: React.FC<ScanQRCodeProps> = ({ onScan }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton icon="camera" label={t('Scan seed from QR code')} onClick={() => setIsOpen(true)} />
      {isOpen && (
        <Modal icon="qr-code" open={isOpen} title={t('Scan QR Code')} width={625} onClose={() => setIsOpen(false)}>
          <QrReader
            onRead={payload => {
              setIsOpen(false);
              onScan(payload);
            }}
          />
        </Modal>
      )}
    </>
  );
};
