import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from 'components/Header';

import styles from './Layout.css';

export const Layout = () => (
  <>
    <Header />
    <main className={styles.main}>
      <Outlet />
    </main>
  </>
);
