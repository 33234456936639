import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from 'layouts/Layout';
import { WalletCreate } from 'pages/WalletCreate';
import { WalletSetup } from 'pages/WalletSetup';
import { Account } from 'pages/Account';
import { WalletImport } from 'pages/WalletImport';
import { urls } from './urls';

export const RoutesComponent = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<WalletSetup />} />
      <Route path={urls.walletCreate} element={<WalletCreate />} />
      <Route path={urls.account} element={<Account />} />
      <Route path={urls.walletImport} element={<WalletImport />} />
    </Route>
  </Routes>
);
