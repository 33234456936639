import React, { useEffect, useRef } from 'react';
import jsQR from 'jsqr';

interface QrReaderProps {
  onRead: (payload: string) => void;
}

export const QrReader: React.FC<QrReaderProps> = ({ onRead }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef === null || canvasRef.current === null) return undefined;

    const video = document.createElement('video');
    const canvas = canvasRef.current.getContext('2d');
    let cameraStream: MediaStream;

    function tick() {
      if (canvasRef === null || canvasRef.current === null || canvas === null) return;

      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasRef.current.height = video.videoHeight;
        canvasRef.current.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasRef.current.width, canvasRef.current.height);

        const imageData = canvas.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });

        if (code) {
          onRead(code.data);
        }
      }
      requestAnimationFrame(tick);
    }

    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then(stream => {
      cameraStream = stream;
      video.srcObject = stream;
      video.setAttribute('playsinline', 'true'); // required to tell iOS safari we don't want fullscreen
      video.play();
      requestAnimationFrame(tick);
    });

    return () => {
      video.pause();
      cameraStream.getTracks().forEach((track: any) => track.stop());
    };
  }, []);

  return <canvas ref={canvasRef} style={{ width: 625 }} />;
};

export default QrReader;
