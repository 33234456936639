import { injectable, inject } from 'inversify';
import * as Keeta from '@keetapay/keetanet-client';

import { TYPES } from 'inversify/types';

import { IDatabaseService, IWalletService } from 'services/types';

@injectable()
export class WalletService implements IWalletService {
  private databaseService: IDatabaseService;

  constructor(@inject(TYPES.DatabaseService) databaseService: IDatabaseService) {
    this.databaseService = databaseService;
  }

  createWallet = (options?: { password?: string }) => {
    const seedBuffer = Keeta.lib.Account.generateRandomSeed();
    const seed = [...new Uint8Array(seedBuffer)].map(d => d.toString(16).padStart(2, '0')).join('');
    this.databaseService.saveWallet({ seed, accounts: [] }, { password: options?.password });
  };

  addAccount = (options?: { password?: string }) => {
    const { seed, accounts } = this.databaseService.loadWallet({ password: options?.password });
    let i = 0;
    while (accounts.includes(i)) {
      i += 1;
    }
    const newAccounts = accounts.concat(i).sort();
    this.databaseService.saveWallet({ seed, accounts: newAccounts }, { password: options?.password });
  };

  sendToAccount = (to: string, amount: number, client: Keeta.UserClient) => client.send(to, amount);
}
