import { injectable } from 'inversify';
import { entropyToMnemonic, generateMnemonic, mnemonicToEntropy } from 'bip39';

import { IBip39Service } from 'services/types';

@injectable()
export class Bip39Service implements IBip39Service {
  convertMnemonicToSeed = (mnemonic: string): string => mnemonicToEntropy(mnemonic);

  convertSeedToMnemonic = (seed: string): string => entropyToMnemonic(seed);

  generateMnemonic = () => generateMnemonic(256);
}
