import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@lfg/core';
import { ScanQRCode } from 'components/ScanQRCode';

export const WalletImportSeed: React.FC = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext<{ seed: string }>();
  return (
    <Controller
      name="seed"
      render={({ field }) => (
        <Input
          {...field}
          error={errors.seed?.message}
          endAdornment={
            <ScanQRCode
              onScan={payload => {
                field.onChange(payload);
                field.onBlur();
              }}
            />
          }
          label={t('Enter 64 character recovery seed')}
          value={field.value || ''}
          fullWidth
        />
      )}
    />
  );
};
