import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Grid, Input, Typography } from '@lfg/core';

export const WalletImportMnemonic: React.FC = () => {
  const { fields } = useFieldArray({ name: 'words' });
  const {
    register,
    setValue,
    setFocus,
    formState: { errors },
  } = useFormContext<{ words: { value: string }[] }>();

  const NUMBER_OF_FIELDS = 24;

  const onPaste = async (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const text = event.clipboardData.getData('text');

    const textArray = text.trim().split(' ');
    textArray.forEach((value, index) => {
      if (index < NUMBER_OF_FIELDS) {
        setValue(`words.${index}.value`, value);
      }
    });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === ' ') {
      event.preventDefault();
      if (index < NUMBER_OF_FIELDS - 1) {
        setFocus(`words.${index + 1}.value`);
      }
    }
  };

  return (
    <>
      <Typography variant="body">Fill in each text field below with your secret key phrase</Typography>
      <Grid container spacing={1}>
        {fields.map((field, index) => (
          <Grid xs={3} key={field.id}>
            <Input
              error={errors.words && index in errors.words ? errors.words[index]?.value?.message : ''}
              startAdornment={`${index + 1}. `}
              onPaste={onPaste}
              {...register(`words.${index}.value`)}
              onKeyDown={event => onKeyDown(event, index)}
              fullWidth
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
