import { injectable } from 'inversify';
import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';

import { STORE_KEY } from 'common/constants';
import { IDatabaseService, WalletData } from 'services/types';

@injectable()
export class DatabaseService implements IDatabaseService {
  loadWallet = (options?: { password?: string }): WalletData => {
    const encryptedWallet = localStorage.getItem(STORE_KEY);
    return encryptedWallet
      ? JSON.parse(AES.decrypt(encryptedWallet, options?.password ?? '').toString(CryptoJS.enc.Utf8))
      : undefined;
  };

  saveWallet = (walletData: WalletData, options?: { password?: string }) => {
    const encryptedWallet = AES.encrypt(JSON.stringify(walletData), options?.password ?? '').toString();
    localStorage.setItem(STORE_KEY, encryptedWallet);
  };
}
