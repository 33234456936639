import React, { useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IconButton } from '../IconButton';

interface InputPasswordProps {
  disabled?: boolean;
  error?: string;
  fullWidth?: boolean;
  label?: string;
  name?: string;
  startAdornment?: any;
  value?: string;
  onBlur?: (...event: any[]) => void;
  onChange?: (...event: any[]) => void;
}

export const InputPassword = React.forwardRef<HTMLInputElement, InputPasswordProps>(
  ({ disabled, error, fullWidth, label, startAdornment, name, value, onBlur, onChange }, ref) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const endAdornment = useMemo(
      () => (
        <IconButton
          icon={showPassword ? 'visibility' : 'visibility-off'}
          label={t('Show password as text')}
          onClick={() => setShowPassword(!showPassword)}
        />
      ),
      [showPassword, t]
    );

    return (
      <TextField
        error={!!error}
        helperText={error && t(error)}
        fullWidth={fullWidth}
        disabled={disabled}
        label={label}
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        inputRef={ref}
        InputProps={{ startAdornment, endAdornment }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
      />
    );
  }
);
