import { yupResolver } from '@hookform/resolvers/yup';
import { array, object, string } from 'yup';

import { form } from 'common/messages';

const schema = object({
  option: string().required(form.requiredField),
  seed: string().when('option', {
    is: 'seed',
    then: seedSchema =>
      seedSchema
        .required(form.requiredField)
        .matches(/[0-9A-F]{64}/, form.invalidField)
        .max(64, form.invalidField),
  }),
  words: array().when('option', {
    is: 'mnemonic',
    then: wordsSchema => wordsSchema.of(object().shape({ value: string().required(form.requiredField) })),
  }),
});

export const resolver = yupResolver(schema);
