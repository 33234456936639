import React from 'react';
import { Box, Modal as MuiModal } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Stack } from '../Stack';
import { Icon } from '../Icon';
import { IconTypes } from '../Icon/mapping';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';

interface ModalProps {
  children: React.ReactElement;
  hideCloseButton?: boolean;
  icon?: IconTypes;
  open: boolean;
  title?: string;
  width?: string | number;
  onClose?: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#383C45',
  borderRadius: '12px',
  p: 0,
};

export const Modal: React.FC<ModalProps> = ({
  hideCloseButton = false,
  icon,
  open,
  title,
  width = 400,
  onClose,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <MuiModal open={open} disableAutoFocus>
      <Box sx={{ ...style, width }}>
        <Stack direction="row" justifyContent="space-between" padding={2} minHeight={40} alignItems="center">
          <Stack direction="row" spacing={0.5}>
            {icon && <Icon type={icon} />} {title && <Typography variant="headingSm">{title}</Typography>}
          </Stack>
          <div>
            {!hideCloseButton && (
              <IconButton icon="close" onClick={onClose} color="secondary" label={t('Close modal')} />
            )}
          </div>
        </Stack>
        {children}
        <Stack minHeight={52} />
      </Box>
    </MuiModal>
  );
};
