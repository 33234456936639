import React, { useCallback } from 'react';
import { Button, Stack, Typography, InputPassword, Grid } from '@lfg/core';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { resolver } from './resolver';

export type WalletPasswordFormData = {
  password: string;
  passwordConfirmation: string;
};

interface WalletPasswordProps {
  onComplete: (payload: WalletPasswordFormData) => void;
}

export const WalletPassword: React.FC<WalletPasswordProps> = ({ onComplete }) => {
  const { t } = useTranslation();

  const methods = useForm<WalletPasswordFormData>({
    resolver,
  });

  const handleSubmit = useCallback(
    (data: WalletPasswordFormData) => {
      onComplete(data);
    },
    [onComplete]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Stack spacing={2} alignItems="flex-start">
          <Typography variant="headingLg">{t('(Optional) Please set your wallet password')}</Typography>
          <Typography variant="body">
            {t(
              'This password will be used to encrypt and store your wallet . Recommended to skip if on a public computer.'
            )}
          </Typography>
          <Box width="100%" textAlign="center">
            <Grid container spacing={4}>
              <Grid xs={6}>
                <InputPassword
                  {...methods.register('password')}
                  error={methods.formState.errors.password?.message}
                  fullWidth
                  label={t('Enter password')}
                />
              </Grid>
              <Grid xs={6}>
                <InputPassword
                  {...methods.register('passwordConfirmation')}
                  error={methods.formState.errors.passwordConfirmation?.message}
                  fullWidth
                  label={t('Confirm password')}
                />
              </Grid>
            </Grid>
          </Box>
          <Box width="100%" textAlign="center">
            <Button type="submit">{t('Continue')}</Button>
          </Box>
          <Box width="100%" textAlign="center">
            <Button type="button" variant="outlined">
              {t('Skip')}
            </Button>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
};
