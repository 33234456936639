import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';

interface AppBarProps extends React.PropsWithChildren {}

export const AppBar: React.FC<AppBarProps> = ({ children }) => (
  <MuiAppBar enableColorOnDark>
    <MuiToolbar>{children}</MuiToolbar>
  </MuiAppBar>
);
