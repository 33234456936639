import React from 'react';
import MuiTypography from '@mui/material/Typography';

interface TypographyProps extends React.PropsWithChildren {
  variant: 'display' | 'headingLg' | 'headingSm' | 'body' | 'bodyStrong' | 'caption' | 'captionStrong' | 'buttonText';
  component?: React.ElementType;
}

export const Typography: React.FC<TypographyProps> = ({ variant, ...props }) => {
  switch (variant) {
    case 'display':
      return <MuiTypography variant="h1" {...props} />;
    case 'headingLg':
      return <MuiTypography variant="h2" {...props} />;
    case 'headingSm':
      return <MuiTypography variant="h3" {...props} />;
    case 'bodyStrong':
      return <MuiTypography variant="body2" {...props} />;
    case 'caption':
      return <MuiTypography variant="subtitle1" component="caption" {...props} />;
    case 'captionStrong':
      return <MuiTypography variant="subtitle2" component="caption" {...props} />;
    case 'buttonText':
      return <MuiTypography variant="button" {...props} />;
    case 'body':
    default:
      return <MuiTypography variant="body1" {...props} />;
  }
};
