import { interfaces } from 'inversify';

import { IBip39Service, IDatabaseService, IWalletService } from 'services/types';

type ServiceIdentifier<T> = interfaces.ServiceIdentifier<T>;

export const TYPES = {
  Bip39Service: 'Bip39Service' as ServiceIdentifier<IBip39Service>,
  DatabaseService: 'DatabaseService' as ServiceIdentifier<IDatabaseService>,
  WalletService: 'WalletService' as ServiceIdentifier<IWalletService>,
};
