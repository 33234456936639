import { yupResolver } from '@hookform/resolvers/yup';
import { object, ref, string } from 'yup';

import { form } from 'common/messages';

const schema = object({
  password: string().required(form.requiredField),
  passwordConfirmation: string().oneOf([ref('password')], form.passwordsMustMatch),
});

export const resolver = yupResolver(schema);
