import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { ContainerProvider } from 'inversify/ContainerProvider';
import { RoutesComponent } from 'routing/Routes';
import { theme } from 'styles/theme';

export default function App() {
  return (
    <ContainerProvider>
      <RecoilRoot>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <RoutesComponent />
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </RecoilRoot>
    </ContainerProvider>
  );
}
