import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface InputProps {
  disabled?: boolean;
  endAdornment?: any;
  error?: string;
  fullWidth?: boolean;
  label?: string;
  name?: string;
  startAdornment?: any;
  type?: Exclude<React.InputHTMLAttributes<unknown>['type'], 'checkbox'>;
  value?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled,
      endAdornment,
      error,
      fullWidth,
      label,
      startAdornment,
      type,
      name,
      value,
      onBlur,
      onChange,
      onKeyDown,
      onPaste,
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <TextField
        error={!!error}
        helperText={error && t(error)}
        fullWidth={fullWidth}
        disabled={disabled}
        label={label}
        type={type}
        variant="outlined"
        inputRef={ref}
        InputProps={{ startAdornment, endAdornment }}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        onBlur={onBlur}
        name={name}
      />
    );
  }
);
