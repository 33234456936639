import React from 'react';
import MuiGrid from '@mui/material/Unstable_Grid2';

interface GridProps extends React.PropsWithChildren {
  container?: boolean;
  spacing?: number;
  xs?: number;
  xsOffset?: number;
}

export const Grid: React.FC<GridProps> = ({ children, container = false, spacing = 0, xs = true, xsOffset }) => (
  <MuiGrid container={container} spacing={spacing} xs={xs} xsOffset={xsOffset}>
    {children}
  </MuiGrid>
);
