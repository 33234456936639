import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Stack } from '@lfg/core';
import { WalletGenerateMnemonic } from 'components/WalletGenerateMnemonic';
import { WalletPassword, WalletPasswordFormData } from 'components/WalletPassword';
import { useGenerateWalletMnemonic } from 'providers/wallet';
import { urls } from 'routing/urls';
import { useDependency } from 'hooks/useDependency';
import { TYPES } from 'inversify/types';

export const WalletCreate = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const generateWalletMnemonic = useGenerateWalletMnemonic();
  const { createWallet } = useDependency(TYPES.WalletService);

  useEffect(() => {
    generateWalletMnemonic();
  }, []);

  const onCreateWallet = (payload: WalletPasswordFormData) => {
    createWallet({ password: payload.password });
    navigate(urls.index);
  };

  return (
    <Grid container>
      <Stack alignItems="flex-end" width="100%">
        <IconButton icon="close" label={t('Close')} onClick={() => navigate(urls.index)} />
      </Stack>
      {activeStep === 0 && <WalletGenerateMnemonic next={() => setActiveStep(activeStep + 1)} />}
      {activeStep === 1 && <WalletPassword onComplete={onCreateWallet} />}
    </Grid>
  );
};
