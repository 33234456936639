import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as Keeta from '@keetapay/keetanet-client';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useDependency } from 'hooks/useDependency';
import { TYPES } from 'inversify/types';
import { IDatabaseService, IWalletService, WalletData } from 'services/types';

export const Account = () => {
  const { index } = useParams();
  const [walletData, setWalletData] = useState<WalletData | undefined>();
  const [balance, setBalance] = useState<string | undefined>();
  const toRef = useRef();
  const amountRef = useRef();
  const { loadWallet } = useDependency<IDatabaseService>(TYPES.DatabaseService);
  const { sendToAccount } = useDependency<IWalletService>(TYPES.WalletService);

  const { t } = useTranslation();

  const account = walletData && Keeta.lib.Account.fromSeed(walletData.seed, Number(index));
  const client = account && Keeta.UserClient.fromNetwork('test', account);

  useEffect(() => {
    const data = loadWallet();
    setWalletData(data);
  }, [loadWallet]);

  useEffect(() => {
    if (client) {
      const fetchBalance = async () => {
        const state = await client.state();
        setBalance(state.balance.toString());
      };
      fetchBalance();
    }
  }, [client]);

  if (!walletData) {
    return <CircularProgress />;
  }

  const handleSend = async () => {
    // @ts-ignore
    const to = toRef.current.value;
    // @ts-ignore
    const amount = amountRef.current.value;
    // @ts-ignore
    await sendToAccount(to, amount, client);
    // @ts-ignore
    const state = await client.state();
    setBalance(state.balance.toString());
  };

  const handleFund = async () => {
    // @ts-ignore
    await client.sendFromFountain(account, 10);
    // @ts-ignore
    const state = await client.state();
    setBalance(state.balance.toString());
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom component="div">
        {t('account.details')}
      </Typography>
      <div>
        {t('account.account')}:{account?.publicKeyString.get()}
      </div>
      <div>
        {t('account.balance')}: ${balance}
      </div>
      <Divider sx={{ my: 3 }} />
      <Typography variant="h4" gutterBottom component="div">
        {t('account.send-title')}
      </Typography>
      <TextField id="outlined-basic" label={t('account.send-to')} variant="outlined" inputRef={toRef} fullWidth />
      <TextField
        id="outlined-basic"
        label={t('account.send-amount')}
        variant="outlined"
        inputRef={amountRef}
        fullWidth
      />
      <Button variant="contained" endIcon={<SendIcon />} onClick={handleSend} sx={{ ml: 'auto' }}>
        {t('account.send-button')}
      </Button>
      <Divider sx={{ my: 3 }} />
      <Button variant="contained" onClick={handleFund} sx={{ ml: 'auto' }}>
        {t('account.fund-from-fountain')}
      </Button>
    </div>
  );
};
