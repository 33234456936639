import React from 'react';

import { IconTypes, MappedIcons } from './mapping';

type IconSize = 'sm' | 'md' | 'lg' | 'xl';

interface IconProps {
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'info' | 'warning';
  size?: IconSize;
  type: IconTypes;
}

const mapSizeScale: Record<IconSize, number> = {
  sm: 0.66667, // 16px
  md: 1, // 24px -> default, all icons should be exported as 24 x 24
  lg: 1.5, // 36px
  xl: 2, // 48px
};

export const Icon: React.FC<IconProps> = ({ color = 'primary', size = 'md', type }) => {
  const Component = MappedIcons[type];
  return <Component color={color} style={{ transform: `scale(${mapSizeScale[size]})` }} />;
};
