import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Paper, Icon, Typography } from '@lfg/core';
import { urls } from 'routing/urls';

export const WalletSetup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Stack spacing={8} alignItems="flex-start">
      <Typography variant="headingLg">{t('Set up your wallet')}</Typography>
      <Stack direction="row" spacing={4}>
        <Paper>
          <Stack spacing={4} alignItems="flex-start">
            <Icon type="import" size="xl" />
            <Typography variant="headingSm">{t('Import wallet')}</Typography>
            <Typography variant="body">
              {t('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.')}
            </Typography>
            <Button onClick={() => navigate('/import')}>{t('Import')}</Button>
          </Stack>
        </Paper>
        <Paper>
          <Stack spacing={4} alignItems="flex-start">
            <Icon type="add" size="xl" />
            <Typography variant="headingSm">{t('Create wallet')}</Typography>
            <Typography variant="body">
              {t('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.')}
            </Typography>
            <Button onClick={() => navigate(urls.walletCreate)}>{t('Create')}</Button>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};
