import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Grid, Paper, Stack, Typography } from '@lfg/core';
import { useWalletMnemonic } from 'providers/wallet';

interface WalletGenerateMnemonicProps {
  next: () => void;
}

export const WalletGenerateMnemonic: React.FC<WalletGenerateMnemonicProps> = ({ next }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const mnemonic = useWalletMnemonic();

  return (
    <Stack spacing={2}>
      <Typography variant="headingLg">{t('This is your secret key phrase')}</Typography>
      <Typography variant="body">{t('Use these 24 words in sequential order to recover your Keeta Wallet')}</Typography>
      <Grid container spacing={1} xs={8}>
        {mnemonic.split(' ').map((word, index) => {
          const key = index + word;
          return (
            <Grid key={key} xs={3}>
              <Paper>
                <span>{index + 1}. </span>
                <span>{word}</span>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <Button variant="outlined" icon="copy" onClick={() => navigator.clipboard.writeText(mnemonic)}>
        {t('Copy key phrase')}
      </Button>
      <Checkbox
        label={t('I have securely stored the key phrase in a safe place')}
        isChecked={isChecked}
        onChange={setIsChecked}
      />
      <Button onClick={next} disabled={!isChecked}>
        {t('Continue')}
      </Button>
    </Stack>
  );
};
