import React from 'react';
import MuiStack from '@mui/material/Stack';

interface StackProps extends React.PropsWithChildren {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
  spacing?: number;
  width?: string | number;
  padding?: string | number;
  minHeight?: string | number;
  marginLeft?: string | number;
}

export const Stack: React.FC<StackProps> = ({
  children,
  direction = 'column',
  justifyContent = 'center',
  alignItems = 'center',
  spacing = 0,
  width,
  padding,
  minHeight,
  marginLeft,
}) => (
  <MuiStack
    alignItems={alignItems}
    direction={direction}
    justifyContent={justifyContent}
    spacing={spacing}
    width={width}
    padding={padding}
    minHeight={minHeight}
    marginLeft={marginLeft}
  >
    {children}
  </MuiStack>
);
