import { useCallback } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { TYPES } from 'inversify/types';
import { useDependency } from 'hooks/useDependency';

export const walletMnemonicState = atom({
  key: 'walletMnemonic',
  default: '',
});

export const useGenerateWalletMnemonic = () => {
  const [, setWalletMnemonic] = useRecoilState(walletMnemonicState);
  const { generateMnemonic } = useDependency(TYPES.Bip39Service);
  return useCallback(() => {
    const mnemonic = generateMnemonic();
    setWalletMnemonic(mnemonic);
  }, []);
};

export const useWalletMnemonic = () => useRecoilValue(walletMnemonicState);
