import React from 'react';
import { Paper as MuiPaper } from '@mui/material';

import styles from './Paper.css';

interface PaperProps extends React.PropsWithChildren {}

export const Paper: React.FC<PaperProps> = ({ children }) => (
  <MuiPaper elevation={0} className={styles.paper}>
    {children}
  </MuiPaper>
);
