import React from 'react';
import MuiIconButton from '@mui/material/IconButton';

import { Icon } from '../Icon';
import { IconTypes } from '../Icon/mapping';

interface IconButtonProps {
  icon: IconTypes;
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'success' | 'info' | 'warning';
  onClick?: () => void;
  label: string;
}

export const IconButton: React.FC<IconButtonProps> = ({ icon, color = 'default', onClick, label }) => (
  <MuiIconButton color={color} onClick={onClick} aria-label={label}>
    <Icon type={icon} />
  </MuiIconButton>
);
