import React from 'react';
import MuiButton from '@mui/material/Button';

import { Icon } from '../Icon';
import { IconTypes } from '../Icon/mapping';

interface ButtonProps extends React.PropsWithChildren {
  icon?: IconTypes;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'contained' | 'outlined';
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'info' | 'warning';
  disabled?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  icon,
  type = 'button',
  variant = 'contained',
  color = 'primary',
  disabled,
  onClick,
}) => {
  const startIcon = icon && <Icon type={icon} />;
  return (
    <MuiButton type={type} variant={variant} color={color} startIcon={startIcon} disabled={disabled} onClick={onClick}>
      {children}
    </MuiButton>
  );
};
