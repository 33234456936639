import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#36EA9A',
    },
    secondary: {
      main: '#E1B4A3',
    },
    background: {
      default: '#32363C',
      paper: '#22252D',
    },
    error: {
      main: '#FF7D7D',
    },
  },
  typography: {
    h1: {
      fontSize: '72px',
      fontWeight: 400,
      lineHeight: '78px',
    },
    h2: {
      fontSize: '44px',
      fontWeight: 400,
      lineHeight: '48px',
    },
    h3: {
      fontSize: '28px',
      fontWeight: 400,
      lineHeight: '36px',
    },
    body1: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '24px',
    },
    subtitle1: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    button: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    h4: undefined,
    h5: undefined,
    h6: undefined,
    caption: undefined,
    overline: undefined,
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: '16px',
          letterSpacing: 0.4,
        },
      },
    },
  },
});
