import 'reflect-metadata';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(<App />);
