import React, { useCallback } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';

interface CheckboxProps {
  label: string;
  hideLabel?: boolean;
  isChecked?: boolean;
  onChange?: (value: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ isChecked, label, hideLabel = false, onChange }) => {
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(!!event.target.checked);
  }, []);

  return !hideLabel ? (
    <FormControlLabel control={<MuiCheckbox onChange={handleChange} defaultChecked={isChecked} />} label={label} />
  ) : (
    <MuiCheckbox onChange={handleChange} checked={isChecked} inputProps={{ 'aria-label': label }} />
  );
};
