import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WalletImportRecovery, WalletImportRecoveryFormData } from 'components/WalletImportRecovery';
import { WalletPassword, WalletPasswordFormData } from 'components/WalletPassword';
import { useDependency } from 'hooks/useDependency';
import { TYPES } from 'inversify/types';
import { Grid, IconButton, Stack } from '@lfg/core';
import { urls } from 'routing/urls';
import { useTranslation } from 'react-i18next';

export const WalletImport: React.FC = () => {
  const [step, setStep] = useState<number | undefined>(1);
  const { createWallet } = useDependency(TYPES.WalletService);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const renderedStep = useMemo(() => {
    switch (step) {
      case 1: {
        // TODO disabled until implement our service
        // eslint-disable-next-line
        const handleComplete = (payload: WalletImportRecoveryFormData) => {
          setStep(2);
        };
        return <WalletImportRecovery onComplete={handleComplete} />;
      }
      case 2: {
        // TODO disabled until implement our service
        // eslint-disable-next-line
        const handleComplete = (payload: WalletPasswordFormData) => {
          createWallet({ password: payload.password });
          setStep(undefined);
        };
        return <WalletPassword onComplete={handleComplete} />;
      }
      default:
        return null;
    }
  }, [createWallet, step]);

  return (
    <Grid container>
      <Stack alignItems="flex-end" width="100%">
        <IconButton icon="close" label={t('Close')} onClick={() => navigate(urls.index)} />
      </Stack>
      {renderedStep}
    </Grid>
  );
};
