import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

import styles from './Select.css';

export interface SelectOption {
  value: any;
  label: string;
}

interface SelectProps {
  error?: string;
  fullWidth?: boolean;
  label: string;
  name?: string;
  options: SelectOption[];
  value?: any;
  onBlur?: (...event: any[]) => void;
  onChange?: (...event: any[]) => void;
}

export const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  ({ error, fullWidth, label, name, options, value, onBlur, onChange }, ref) => {
    const { t } = useTranslation();
    return (
      <TextField
        select
        error={!!error}
        helperText={error && t(error)}
        label={label}
        fullWidth={fullWidth}
        inputRef={ref}
        className={styles.wrapper}
        variant="outlined"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
);
